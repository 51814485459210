interface Props {
  onClick: () => void;
}

export default function SubscribeButton({ onClick }: Props) {
  return (
    <div className="mt-6 flex flex-col justify-center px-4 sm:flex-row ">
      <button
        onClick={onClick}
        type="button"
        className="rounded-md bg-gradient-to-r from-red-400 to-orange-400 px-3.5 py-3 text-sm font-semibold text-white font-poppins items-center shadow-sm hover:bg-gray-100"
      >
        <span className="flex items-center justify-center space-x-1">
          Subscribe to my fitness app &rarr;
        </span>
      </button>
    </div>
  );
}
