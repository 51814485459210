import { gql, useLazyQuery } from "@apollo/client";
import {
  CalendarDaysIcon,
  CheckBadgeIcon,
  LockOpenIcon,
  VideoCameraIcon,
} from "@heroicons/react/20/solid";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LegalFooter from "../components/legal-footer";
import ScreenshotScrollview from "../components/screenshot-scroll";
import { PlanType, TOKEN } from "../constants";
import { classNames } from "../helpers";
import { useCreator } from "../services/creator.service";
import NotFoundScreen from "./404";
import LoadingPage from "./loading";

const points = (name: string) => [
  {
    icon: LockOpenIcon,
    text: `Access to all of ${name}'s fitness programs and content`,
  },
  {
    icon: VideoCameraIcon,
    text: "Fully recorded fitness programs with videos and instructions",
  },
  {
    icon: CalendarDaysIcon,
    text: "New fitness content continuously updated with programs and exercises",
  },
];

const GET_STRIPE_CHECKOUT_SESSION = gql`
  query getStripeCheckout($planId: String!) {
    getStripeCheckout(planId: $planId) {
      url
    }
  }
`;

export default function CreatorSubscriptions() {
  const { username } = useParams();
  const navigate = useNavigate();
  const { creator, loading } = useCreator(username);
  const [getStripeCheckout, { loading: checkoutLoading }] = useLazyQuery<{
    getStripeCheckout: { url: string };
  }>(GET_STRIPE_CHECKOUT_SESSION);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  console.log(selectedPlan);
  if (loading || checkoutLoading) return <LoadingPage />;
  if (!creator) return <NotFoundScreen />;

  const items = points(creator.fullname);
  const subscriptions = creator.creatorSubscriptions
    .map(({ planId, planType, priceDetails }) => {
      const type = planType === PlanType.MONTHLY ? "Monthly" : "Yearly";
      return {
        text: `${creator.firstName}'s ${type}`,
        planId,
        planType,
        priceDetails,
      };
    })
    .sort(({ planType }) => (planType === PlanType.MONTHLY ? 1 : -1));

  async function handleSubscribeCheckout(planId: string) {
    const token = localStorage.getItem(TOKEN);
    if (!token) {
      navigate("/auth");
      return;
    }
    setSelectedPlan(planId);
    const { data, error } = await getStripeCheckout({ variables: { planId } });
    console.log(data, error);
    if (!data) {
      alert("Something went wrong. Please try again later.");
      return;
    }
    const uri = data.getStripeCheckout.url;
    window.location.href = uri;
  }

  const handleNavigateToCreator = () => navigate(`/${username}`);

  return (
    <div className="flex flex-col justify-center items-center pt-4">
      <div className="px-4 sm:px-6 lg:px-8 z-20">
        <div
          className="flex flex-col justify-center items-center"
          onClick={handleNavigateToCreator}
        >
          <img
            className="h-20 w-20 object-contain rounded-full ring-4 ring-white"
            src={creator.profileUrl}
            alt={creator.fullname}
          />

          <div className="sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-center sm:space-x-6 sm:pb-1">
            <div className="mt-4 min-w-0 flex-1 sm:hidden md:block">
              <h1 className="truncate font-poppins text-2xl font-semibold text-white text-center">
                {creator.fullname}
              </h1>
              <h1 className="flex items-center justify-center">
                <span className="text-gray-400 mr-1 font-poppins font-medium">
                  {`@${creator.username}`}
                </span>
                <span className="inline-block rounded-full bg-white border-none">
                  <CheckBadgeIcon className="h-4 w-4 text-blue-500" />
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
          <h1 className="truncate text-2xl font-bold text-gray-900 text-center">
            {creator.fullname}
          </h1>
        </div>
      </div>

      <div className="m-2 flex flex-row">
        {creator.content.map((content) => (
          <span
            key={content}
            className="inline-flex mr-1 items-center rounded-full bg-red-400 px-2.5 py-1 text-xs font-semibold text-white ring-1 ring-inset ring-pink-700/10 text-center font-poppins"
          >
            {content}
          </span>
        ))}
      </div>
      <ScreenshotScrollview />
      <div className="flex flex-col justify-start items-start space-y-5 m-4">
        {items.map((item) => (
          <div
            key={item.text}
            className="flex flex-row justify-center items-center space-x-2"
          >
            <div className="flex flex-row justify-center items-center rounded-full border-red-500 px-2 bg-gray-200 py-2 text-xs font-semibold text-white ring-1 ring-inset ring-red-400 mr-3 text-center">
              <item.icon className="h-4 w-4 text-red-400" />
            </div>
            <span className="text-white font-poppins text-sm mt-1">
              {item.text}
            </span>
          </div>
        ))}
      </div>
      <div className="relative flex py-4 items-center w-full">
        <div className="flex-grow border-t border-gray-300" />
        <span className="flex-shrink mx-4">
          <h1 className="text-gray-100 font-poppins text-sm mt-1">
            Save{" "}
            <span className="text-orange-200 font-poppins font-semibold">
              16.6%
            </span>{" "}
            with yearly plan
          </h1>
        </span>
        <div className="flex-grow border-t border-gray-300" />
      </div>
      <div className="flex flex-col justify-center items-center w-full px-4">
        {subscriptions.map(({ text, planId, planType, priceDetails }) => (
          <button
            onClick={() => handleSubscribeCheckout(planId)}
            key={planId}
            type="button"
            className={classNames(
              planType === PlanType.MONTHLY
                ? "bg-gradient-to-r from-gray-700 via-gray-900 to-black"
                : "bg-gradient-to-r from-orange-500 to-rose-400",
              "relative inline-flex items-center justify-start border border-transparent font-medium rounded-md mt-5 w-full lg:w-1/3  flex-col px-3 py-2  cursor-pointer hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            )}
          >
            <div className="flex items-center justify-between rounded-md">
              <div className="flex items-center space-x-3 w-full">
                <img
                  src={
                    planType === PlanType.MONTHLY
                      ? creator.profileUrl
                      : creator.coverUrl
                  }
                  alt={creator.fullname}
                  className="w-14 h-14 rounded-md object-cover"
                />
                <div className="flex flex-col text-start w-full">
                  <span className="font-poppins text-lg">{text}</span>
                  <span
                    className={classNames(
                      planType === PlanType.MONTHLY
                        ? "text-gray-400"
                        : "text-gray-200",
                      "font-poppins uppercase text-xs"
                    )}
                  >
                    {planType === PlanType.MONTHLY
                      ? "Monthly app subscription"
                      : `Save with yearly subscription`}
                  </span>
                </div>
              </div>
              <div className="flex items-end flex-col text-end">
                <span className="font-poppins text-sm mt-1 space-y-1">
                  {`$${priceDetails.price} /${
                    planType === PlanType.MONTHLY ? "month" : "year"
                  }`}
                </span>
              </div>
            </div>
          </button>
        ))}
      </div>
      <LegalFooter />
    </div>
  );
}

/* <div className="flex justify-between items-center">
              <div className="flex">
                <img
                  src="path/to/image.jpg" // Replace with the actual image source
                  alt="Button Icon"
                  className="w-6 h-6 mr-2" // Adjust the width and height as needed
                />
                <div className="flex flex-col text-start">
                  <span className="font-poppins text-xl">{text}</span>
                  <span
                    className={classNames(
                      planType === PlanType.MONTHLY
                        ? "text-gray-700"
                        : "text-white",
                      "font-poppins uppercase text-xs mt-1.5"
                    )}
                  >
                    {planType === PlanType.MONTHLY
                      ? "Monthly app subscription"
                      : `Save with yearly subscription`}
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center">
                <span className="font-poppins text-sm mt-1">
                  {`$${priceDetails.price}`}
                </span>
                <span className="font-poppins uppercase text-xs mt-1">
                  {priceDetails.currency}
                </span>
              </div>
            </div> */
