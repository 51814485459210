import { useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import CreatorHeader from "../components/creator-header";
import CreatorLinks from "../components/creator-links";
import Footer from "../components/footer";
import ProgramGridList from "../components/program-grid-list";
import SubscribeButton from "../components/subscribe-button";
import { CREATOR_USERNAME, TOKEN } from "../constants";
import "../index.css";
import { useCreator } from "../services/creator.service";
import NotFoundScreen from "./404";
import LoadingPage from "./loading";

interface Props {
  username: string;
}
const uri = process.env.REACT_APP_GOOGLE_AUTH_URL;
export default function CreatorPage() {
  const navigate = useNavigate();
  const { username } = useLoaderData() as Props;
  const { creator, loading } = useCreator(username);

  useEffect(() => {
    if (!creator) return;
    const { fullname } = creator;
    document.title = `${fullname} | Fitness App | Trainer.ai`;
    localStorage.setItem(CREATOR_USERNAME, username);
  }, [creator, username]);

  if (loading) return <LoadingPage />;
  if (!creator) return <NotFoundScreen />;

  function handleSubscribePress() {
    const token = localStorage.getItem(TOKEN);
    if (!token) {
      window.open(uri);
    } else {
      navigate(`/${username}/subscriptions`);
    }
  }

  return (
    <>
      <CreatorHeader creator={creator} />
      <SubscribeButton onClick={handleSubscribePress} />
      <ProgramGridList programs={creator.programs} />

      <CreatorLinks links={creator.links} />
      <Footer />
    </>
  );
}
