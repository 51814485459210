import one from "../img/screenshots/one.png";
import three from "../img/screenshots/three.png";
import two from "../img/screenshots/two.png";
import four from "../img/screenshots/four.png";
import five from "../img/screenshots/five.png";
const images = [one, two, three, four, five];

export default function ScreenshotScrollview() {
  return (
    <div className="mx-auto max-w-2xl sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
      <div className="mt-1 overflow-x-scroll snap-type-mandatory">
        <div className="flex flex-row snap-align-start snap-mandatory">
          {images.map((src) => (
            <img
              src={src}
              alt="screenshot"
              className="h-80 object-contain snap-center"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
