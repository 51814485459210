import Lottie from "lottie-react";
import { useNavigate, useParams } from "react-router-dom";
import { useCreator } from "../services/creator.service";
import NotFoundScreen from "./404";
import LoadingPage from "./loading";

import { ANDROID_URL, IOS_URL, PlanType } from "../constants";
import AppStore from "../img/appstore.png";
import GooglePlayStoreIcon from "../img/google-play-badge.png";
import loadingAnimation from "../lotties/success.json";
import { useMe } from "../services/user.service";

import LegalFooter from "../components/legal-footer";

export default function Success() {
  const { username, planId } = useParams();
  const { me, loading: meLoading } = useMe();
  const { creator, loading } = useCreator(username);
  const navigate = useNavigate();
  if (loading || meLoading) return <LoadingPage />;
  if (!creator) return <NotFoundScreen />;
  const purchasedPlan = creator.creatorSubscriptions.find(
    (subscription) => subscription.planId === planId
  );

  const onAppRedirect = () => navigate("/app");
  const onWebRedirect = (uri: string) => (window.location.href = uri);

  return (
    <>
      <div className="relative">
        <img
          className="h-50 w-full object-cover lg:h-80 xl:h-80"
          src={creator.coverUrl}
          alt={creator.fullname}
        />
        <div
          className="overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7))",
          }}
        />
      </div>

      <div className="flex flex-col -mt-12 lg:-mt-14 px-4 justify-center items-center">
        <Lottie
          animationData={loadingAnimation}
          className="h-24 lg:h-32"
          loop={false}
        />
        <div className="flex flex-col items-center justify-center px-2">
          <h1 className="text-lg font-semibold text-center">
            Congrats {me.firstName}!
          </h1>
          <h1 className="text-sm font-semibold text-center text-gray-400 mt-1">
            You have successfully subscribed to {creator.fullname}'s Exclusive
            Fitness App!
          </h1>
        </div>
        <div className="flex items-center py-3 justify-center border border-gray-600 rounded-lg shadow-lg shadow-zinc-800 mt-5 w-full lg:w-1/3">
          <img
            className="h-16 w-16 rounded-full"
            src={creator.profileUrl}
            alt={creator.fullname}
          />
          <div className="ml-6">
            <div className="text-lg font-medium text-gray-100">
              {purchasedPlan?.planType === PlanType.MONTHLY
                ? "Monthly Subscription"
                : "Yearly Subscription"}
            </div>
            <div className="srounded text-sm text-gray-400">
              {creator.fullname} - @{creator.username}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center px-4 mt-5">
        <button
          onClick={onAppRedirect}
          type="button"
          className="rounded-md bg-gradient-to-r from-red-400 to-orange-400 px-3.5 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full lg:w-1/3"
        >
          Take me to the app &rarr;
        </button>
        <h1 className="text-sm font-semibold text-center text-gray-400 mt-4">
          Your new subscription is synced to your account{" "}
          <span className="text-red-400">{me.email}</span>.
        </h1>
        <h1 className="text-sm font-semibold text-center text-gray-400 mt-2">
          Download the app to access your subscription!
        </h1>

        <div className="flex items-center justify-center mt-6">
          <img
            style={{
              width: 150,
            }}
            src={AppStore}
            alt="AppStore"
            onClick={() => onWebRedirect(IOS_URL)}
            className="w-full cursor-pointer"
          />
          <img
            style={{
              width: 165,
              height: 70,
            }}
            src={GooglePlayStoreIcon}
            alt="Google Play Store"
            onClick={() => onWebRedirect(ANDROID_URL)}
            className="w-full cursor-pointer"
          />
        </div>
      </div>
      <LegalFooter />
    </>
  );
}
