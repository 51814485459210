const deeplink = "trainerai://Auth";

export default function TestDeeplink() {
  function testDeeplink() {
    window.location.href = deeplink;
  }

  return (
    <div>
      <h1>Test</h1>
      <button onClick={testDeeplink}>Test Deeplink</button>
    </div>
  );
}
