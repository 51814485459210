export const TOKEN = "token";
export const CREATOR_USERNAME = "creatorUsername";
export const IOS_URL = "https://apps.apple.com/ca/app/trainer-ai/id6444597107";
export const ANDROID_URL =
  "https://play.google.com/store/apps/details?id=com.daylonball.Trainerai";
export const DEEPLINK_URL = "Trainerai://Home";
export const WEB_URL = "https://www.linkedin.com/company/trainerai/";
export enum PlanType {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}
