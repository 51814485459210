import { ProgramPlan } from "../types";
import ProgramCard from "./program-card";

interface Props {
  programs: ProgramPlan[];
}

export default function ProgramGridList({ programs }: Props) {
  return (
    <>
      <div className="mx-auto max-w-2xl px-4 py-6 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="flex items-center justify-between">
          <h2 className="lg:text-xl text-md font-bold tracking-tight text-gray-100 font-poppins">
            Trending Programs
          </h2>
          <div className=" text-sm font-medium text-blue-400 hover:text-blue-500 md:block">
            View all
            <span aria-hidden="true"> &rarr;</span>
          </div>
        </div>
        <div className="mt-4 overflow-x-scroll snap-type-mandatory">
          <div className="flex gap-x-4 sm:gap-x-6 md:gap-x-8 snap-align-start snap-mandatory">
            {programs.map((product) => (
              <ProgramCard key={product.id} program={product} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
